import React from 'react'
import GithubStatistics from './GithubStatistics'
import '../css/App.css'
class App extends React.Component {
  render() {
    return (
      <div>
        <GithubStatistics />
      </div>
    )
  }
}



export default App
